@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");

* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}

:root {
 --header-height: 4.5rem;
 --first-color: hsl(28, 88%, 62%);
 --title-color: hsl(0, 0%, 95%);
 --text-color: hsl(0, 0%, 75%);
 --body-color: hsl(0, 0%, 6%);
 --body-font: "Montserrat", sans-serif;
 --second-font: "Dancing Script", cursive;
 --h2-font-size: 1.25rem;
 --small-font-size: 0.813rem;
 --font-semi-bold: 600;
 --z-tooltip: 10;
 --z-fixed: 100;
}

body {
 background-color: var(--body-color);
 color: var(--text-color);
 font-family: var(--body-font);
}

ul {
 list-style: circle;
}

a {
 text-decoration: none;
  color: var(--first-color);
}
a:hover, a:visited {
 text-decoration: underline;
 color: #e9e8e8;
}

.card-body a {
  color: #021d93;
  text-decoration: none;
}
.card-body a:hover {

  text-decoration: underline;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* fade in animation */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* fade out animation */
@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}
